var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BsSearch } from 'react-icons/bs';
import { GrFilter } from 'react-icons/gr';
import { FiPlus } from 'react-icons/fi';
import api from '../../services/api';
import Filter from '../../components/Filter';
import useAuth from '../../hooks/useAuth';
import useTitle from '../../hooks/useTitle';
import { Table } from 'template/lib';
import styles from './styles.module.scss';
var Profile = function () {
    var history = useHistory();
    // button function for navigate to detail page
    var lineBtnsWithoutAssginTo = [
        {
            name: 'Detalhes',
            icon: _jsx(BsSearch, {}, void 0),
            fn: function (id) {
                history.push("/profile/" + id);
            }
        }
    ];
    // buttons for table menu
    var menuButtons = [
        {
            id: 3,
            icon: _jsx(GrFilter, {}, void 0),
            title: 'Filtrar',
            fn: function () {
                if (filter === false) {
                    setFilter(true);
                }
                else {
                    setFilter(false);
                }
            }
        },
        {
            id: 2,
            icon: _jsx(FiPlus, {}, void 0),
            title: 'Novo Suporte',
            fn: function () {
                history.push('/create');
            }
        }
    ];
    var _a = useState([]), helps = _a[0], setHelps = _a[1];
    var _b = useState(1), page = _b[0], setPage = _b[1];
    var _c = useState(15), pageSize = _c[0], setPageSize = _c[1];
    var _d = useState(false), hasNext = _d[0], setHasNext = _d[1];
    var _e = useState(false), filter = _e[0], setFilter = _e[1];
    var _f = useState(0), totalPages = _f[0], setTotalPages = _f[1];
    var _g = useState([]), filterVal = _g[0], setFilterVal = _g[1];
    var _h = useState(), filterState = _h[0], setFilterState = _h[1];
    var _j = useState(true), loading = _j[0], setLoading = _j[1];
    var _k = useAuth(), login = _k.login, handleLogout = _k.handleLogout;
    var setTitle = useTitle().setTitle;
    var config = {
        headers: { Authorization: "Bearer " + (login === null || login === void 0 ? void 0 : login.token) }
    };
    useEffect(function () {
        if (login && Date.now() > (login === null || login === void 0 ? void 0 : login.expiresIn) * 1000) {
            handleLogout();
            history.push('/');
        }
        if (!login) {
            history.push('/');
        }
        setTitle('Início');
        if (login && Date.now() > (login === null || login === void 0 ? void 0 : login.expiresIn) * 1000) {
            handleLogout();
            history.push('/');
        }
        api.get('/helps/helpFilters', config).then(function (response) {
            try {
                setFilterVal(response.data);
            }
            catch (e) {
                handleLogout();
            }
        });
        //creating the filter route 
        var urlTotalPages = "/helps/pendingHelpsTotalPages?pagesize=" + pageSize;
        var urlPendingHelps = "/helps/pendingHelps?page=" + page + "&pagesize=" + pageSize;
        if (filterState) {
            for (var key in filterState) {
                var value = filterState[key];
                if (value != null && value != undefined && value != '') {
                    urlTotalPages += "&" + key + "=" + value;
                    urlPendingHelps += "&" + key + "=" + value;
                }
            }
        }
        api.get(urlTotalPages, config)
            .then(function (response) {
            setTotalPages(response.data);
            try {
                if (response.data > page) {
                    setHasNext(true);
                }
                else {
                    setHasNext(false);
                }
            }
            catch (e) {
                handleLogout();
                history.push('/');
            }
        });
        api.get(urlPendingHelps, config)
            .then(function (response) {
            try {
                setHelps(response.data);
            }
            catch (e) {
                handleLogout();
                history.push('/');
            }
        }).then(function () { return setLoading(false); });
    }, [page, pageSize, filterState]);
    var closeFilter = function () {
        setFilter(false);
    };
    function pages() {
        setPage(1);
    }
    return (_jsxs("div", __assign({ className: styles.container }, { children: [filter &&
                _jsx("div", __assign({ className: 'm-4' }, { children: _jsx(Filter, { page: pages, closer: closeFilter, data: filterVal, stateOfFilter: setFilterState }, void 0) }), void 0), _jsx("div", __assign({ className: "" + (!filter && styles.content) }, { children: _jsx(Table, { setSize: setPageSize, size: pageSize, hasNext: hasNext, page: page, setPage: setPage, data: helps, totalPAges: totalPages, headerButtons: menuButtons, lineButton: lineBtnsWithoutAssginTo, loading: loading, title: 'Chamados', columns: [
                        { label: 'ID', key: 'id' },
                        { label: 'Aplicação', key: 'applicationName' },
                        { label: 'Descrição', key: 'especification' },
                        { label: 'Status', key: 'statusName' },
                        { label: 'Usuário', key: 'requestUserName' },
                    ] }, void 0) }), void 0)] }), void 0));
};
export default Profile;
