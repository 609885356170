var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import './styles.css';
var DateInput = function (props) {
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'containerDateInput' }, { children: [_jsxs("label", __assign({ className: "dateInputLabel" }, { children: [props.label, ":"] }), void 0), _jsx("div", { children: _jsx("input", { onChange: props.onChange, name: props.name, value: props.value, type: 'date', className: "dateInput  " + (props.resize ? 'dateInputWidthResized' : 'dateInputWidthDefault') }, void 0) }, void 0)] }), void 0) }, void 0));
};
export default DateInput;
