var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import './styles.css';
var TextBox = function (props) {
    return (_jsxs("div", __assign({ style: { width: !!props.width ? props.width : '70%' }, className: "textBoxContainer " + (!!props.height ? 'h-' + props.height : 'h-1/6') + " py-4 " }, { children: [_jsxs("label", __assign({ className: "textBoxLabel block font-semibold mb-2 text-gray-600 " }, { children: [props.label, ":"] }), void 0), _jsx("textarea", { onChange: props.onChange, name: props.name, disabled: props.disabled, value: props.inicialValue, className: "textBoxInput block w-full h-24 px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-sm focus:border-blue-500 focus:outline-none focus:ring" }, void 0)] }), void 0));
};
export default TextBox;
