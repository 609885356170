var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import './styles.css';
var InputText = function (props) {
    var value = String(props.inicialValue);
    if (props.label.includes('Data')) {
        value = value.split('T').join(' / ');
    }
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "containerTextInput   " }, { children: [_jsxs("label", __assign({ hidden: props.invisible === true ? true : false, className: "textInputLabel  " }, { children: [props.label, ":"] }), void 0), _jsx("input", __assign({ name: props.name, placeholder: props.placeholder, onChange: props.onChange, disabled: props.disabled }, (props.inicialValue != undefined ? { value: value || '' } : {}), { type: props.isPassword === true ? 'password' : 'text', hidden: props.invisible === true ? true : false, className: "textInput " + (props.resize ? 'textInputWidthResized' : 'textInputWidthDefault') }), void 0)] }), void 0) }, void 0));
};
export default InputText;
